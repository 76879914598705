<template>
  <SidebarCheckboxList
    v-if="filteredCategories.length"
    :title="$t('Category')"
    :search-value="searchValue"
    :items="filteredCategories"
    :items-selected="detailedCategoriesSelected"
    :searchable="false"
    @search-input="searchValue = $event"
    @select="select"
  >
    <template #label="{ item }">
      <span>{{ item.name }}</span>
    </template>
  </SidebarCheckboxList>
</template>

<script>
import { mapState } from 'vuex'
import SidebarCheckboxList from '@/components/filters/sidebar/SidebarCheckboxList.vue'

export default {
  name: 'CategoryFilter',
  components: {
    SidebarCheckboxList,
  },
  data: () => {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState('product', ['categories']),
    ...mapState('filtration', ['productCategoriesSelected']),
    ...mapState('marketCatalog', ['filterMetadata']),
    detailedCategoriesSelected() {
      const arr = []

      for (let category of this.categories) {
        if (this.productCategoriesSelected.includes(category.id)) {
          arr.push(category)
        }
      }

      return arr
    },
    filteredCategories() {
      const productCategoriesSet = new Set((this.filterMetadata?.categories || []).map(c => c.id))
      return this.categories.filter(c => productCategoriesSet.has(c.id))
    },
  },
  methods: {
    select(category) {
      if (this.productCategoriesSelected.some(c => c === category.id)) {
        this.$store.dispatch(
          'filtration/setProductCategoriesSelected',
          this.productCategoriesSelected.filter(c => c !== category.id),
        )
      } else {
        this.$store.dispatch('filtration/setProductCategoriesSelected', [
          ...this.productCategoriesSelected,
          category.id,
        ])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.category-filter {
}
</style>
