const initialAvailableFilters = {
  categories: [],
  countries: [],
  groups: [],
  sorts: [],
}

export const state = {
  sellerProducts: [],
  supplyRequests: [],
  supplyRequest: {},
  totalPages: 1,
  currentPage: 1,
  supplyRequestsTotalPages: 1,
  productsCities: [],
  availableFilters: initialAvailableFilters,
}
