<template>
  <ul class="user-nav">
    <li v-for="route in navigation" :key="route.id" class="user-nav__item">
      <router-link
        :is="route.dropdownLinks ? 'span' : 'router-link'"
        class="user-nav__link"
        :to="{ name: route.name }"
        :active-class="`user-nav__link--current`"
      >
        <i :class="`user-nav__icon icon-${route.icon}`">
          <b-badge v-if="checkShowCounter(route.name)" pill class="quantity">
            {{ counterCount(route.name) }}
          </b-badge>
        </i>
      </router-link>
      <div v-if="route.dropdownLinks" class="user-nav__dropdown">
        <router-link
          v-for="path in route.dropdownLinks"
          :key="path.name"
          :to="{ name: path.name }"
          class="user-nav__dropdown-link"
        >
          {{ $t(path.title) }}
        </router-link>
      </div>
    </li>
    <li class="user-nav__item">
      <a href="#" class="user-nav__link" @click.stop="showNotifications = !showNotifications">
        <span v-if="hasUnreadNotifications" class="unread-marker" />
        <i class="icon-notifications" />
      </a>
      <Notifications
        v-show="showNotifications"
        @setUnreadNotificationsMarker="setUnreadNotificationsMarker"
        @close="closeNotifications"
      />
    </li>
    <li class="user-nav__item">
      <button class="user-nav__link" @click="showSubmenu = !showSubmenu">
        <i class="icon-dots" />
      </button>
      <transition name="user-nav__sub-menu">
        <ul v-if="showSubmenu" class="user-nav__sub-menu">
          <li class="user-nav__sub-menu-item">
            <a class="user-nav__sub-menu-link" :href="LANDING_PAGE_URL" target="_blank">{{ $t('About') }}</a>
          </li>
          <li class="user-nav__sub-menu-item">
            <a class="user-nav__sub-menu-link" :href="`${LANDING_PAGE_URL}/news`" target="_blank">{{ $t('News') }}</a>
          </li>
          <li class="user-nav__sub-menu-item">
            <router-link class="user-nav__sub-menu-link" :to="{ name: 'standarts' }">
              {{ $t('Standards') }}
            </router-link>
          </li>
          <li class="user-nav__sub-menu-item">
            <a
              :href="`${LANDING_PAGE_URL}/voprosy-i-otvety/`"
              class="user-nav__sub-menu-link"
              target="_blank"
              @click="sendMetrics('click_help')"
            >
              {{ $t('FAQ') }}
            </a>
          </li>
          <!-- <li class="user-nav__sub-menu-item">
            <a
              class="user-nav__sub-menu-link"
              href="">{{ $t('Terms') }}</a>
          </li> -->
          <!-- <li class="user-nav__sub-menu-item">
            <a
              class="user-nav__sub-menu-link"
              href="">{{ $t('Support') }}</a>
          </li> -->
        </ul>
      </transition>
    </li>

    <li v-if="$route.meta.hideSidebar" class="user-nav__item user-nav__item--account">
      <router-link
        :is="isSupportAgent ? 'span' : 'router-link'"
        class="user-nav__account-avatar"
        :to="{ name: 'profileIndex' }"
      >
        <img
          :src="
            currentUser.photo_small
              ? resolveAssetUrl(currentUser.photo_small)
              : require('@/assets/images/icons/nophoto.png')
          "
          :alt="currentUser.name"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import Notifications from '@/components/common/Notifications.vue'
import { mapGetters, mapState } from 'vuex'
import { sendMetrics } from '@/utils/metrics'
import { resolveAssetUrl } from '@/helpers/url'
import { LANDING_PAGE_URL } from '@/constants'

export default {
  components: {
    Notifications,
  },
  props: {
    navigation: Array,
  },
  data() {
    return {
      showSubmenu: false,
      showNotifications: false,
      hasUnreadNotifications: false,
      LANDING_PAGE_URL,
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapGetters('user', ['isSupportAgent']),
    ...mapGetters('chat', ['newMessagesCount']),
    ...mapState('notification', ['notificationsWithAction']),
    counters() {
      return {
        chat: this.newMessagesCount,
      }
    },
  },
  watch: {
    notificationsWithAction: {
      handler() {
        this.hasUnreadNotifications = !!this.notificationsWithAction.length
      },
      immediate: true,
    },
  },
  methods: {
    sendMetrics,
    resolveAssetUrl,
    closeNotifications() {
      this.showNotifications = false
    },
    setUnreadNotificationsMarker(value) {
      this.hasUnreadNotifications = value
    },
    checkShowCounter(name) {
      return name in this.counters && this.counters[name] > 0
    },
    counterCount(name) {
      return this.counters[name]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.user-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  // Elements
  .user-nav__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 4px;

      @include media-breakpoint-up(sm) {
        margin-right: 8px;
      }
    }

    &:hover .user-nav__dropdown {
      display: flex;
    }

    &--account {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
  .user-nav__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    color: $Gray;
    font-size: 18px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      color: $White;
      width: 30px;
    }

    // States
    &:hover {
      color: rgba($Gray, 0.3);
      text-decoration: none;
      @include media-breakpoint-down(sm) {
        color: rgba($White, 0.3);
      }

      .quantity {
        opacity: 0.5;
      }
    }
  }

  .user-nav__dropdown {
    display: none;
    position: absolute;
    top: 95%;
    background-color: $White;
    flex-direction: column;
    border-radius: $base-grid * 0.5;
    padding: $base-grid;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 1px 4px rgba($color: $Black, $alpha: 0.1);

    @include media-breakpoint-up(md) {
      top: 100%;
    }
  }

  .user-nav__dropdown-link {
    padding: $base-grid 10px;
    color: $DGray;
    text-decoration: none;
    transition: color $transition;
    border-radius: $base-grid * 0.5;

    &:hover {
      color: $GreenM;
      background-color: rgba($color: $LGray4, $alpha: 0.5);
    }
  }

  // Submenu
  .user-nav__sub-menu {
    list-style: none;
    margin: 0;
    padding: calc(15px + 15 * ((100vw - 320px) / (1046)));
    position: absolute;
    top: 100%;
    right: 0;
    width: 252px;
    background-color: $White;
    box-shadow: 5px 10px 20px rgba(black, 0.1);
    z-index: 10;
    .user-nav__sub-menu-item {
      font-weight: 500;
      & + .user-nav__sub-menu-item {
        margin-top: 14px;
      }
    }
    .user-nav__sub-menu-link {
      font-weight: 500;
      color: $Black;
    }
    // States
    &-enter-active,
    &-leave-active {
      transition: $popoutTransition ease all 0s;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateY(-120%);
    }
  }

  .user-nav__icon {
    position: relative;
  }

  .quantity {
    position: absolute;
    top: -6px;
    left: 60%;
    font-family: $fontMain, sans-serif;
    font-size: 10px;
    padding: 2px 4px;
    font-weight: 600;
    background-color: $White;
    color: $GreenM;
    box-shadow: 0 0 1px 0px $GreenM;

    @include media-breakpoint-up(md) {
      color: $White;
      background-color: $GreenM;
      box-shadow: none;
    }
  }

  .unread-marker {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    display: block;
    background: $PlainRed;
    position: absolute;
    top: 6px;
    right: 10px;
  }

  &__account-avatar {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 8px;

    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
