import { removeTrailingSlashes } from '@/helpers/string'

export const DEV_BASE_URL = removeTrailingSlashes(process.env.DEV_BASE_URL || 'https://dev.baza.store')

export const baseUrl = location.hostname === 'localhost' ? DEV_BASE_URL : location.protocol + '//' + location.hostname
console.log('baseUrl: %s', baseUrl)

export const API_URL = removeTrailingSlashes(process.env.API_URL || `${baseUrl}/api`)

export const WS_URL = removeTrailingSlashes(process.env.WS_URL || baseUrl.replace(/^http/i, 'ws') + '/ws')

// нужно через поиск пользователей получать список агентов поддержки
export const SUPPORT_AGENT_ID = process.env.NODE_ENV == 'production' ? 98 : 90

export const isProdEnv = process.env.NODE_ENV === 'production'

export const isDevClient = window.location.hostname.includes('dev')

export const isProdClient = isProdEnv && !isDevClient

export const CLIENT_URL = 'https://baza.store'
export const LANDING_PAGE_URL = 'https://info.baza.store'

export const USER_TYPE = {
  PENDING: -1,
  ADMIN: 0,
  SELLER: 1,
  BUYER: 2,
  EXPORTER: 3,
  SUPPORT_AGENT: 4,
}

export const USER_NAVIGATION = {
  [USER_TYPE.ADMIN]: {
    mainNavigation: [
      {
        id: 0,
        name: 'home',
        title: 'nav.admin.main.home',
        haveMessages: false,
      },
      {
        id: 2,
        name: 'market',
        title: 'nav.admin.main.market',
        haveMessages: false,
      },
      {
        id: 3,
        name: 'applications',
        title: 'nav.admin.main.applications',
        haveMessages: false,
      },
      {
        id: 4,
        name: 'deliveries',
        title: 'nav.admin.main.deliveries',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 0,
        name: 'providers',
        title: 'nav.admin.additional.providers',
        icon: 'team',
      },
      {
        id: 1,
        name: 'finance',
        title: 'nav.admin.additional.finance',
        icon: 'purse',
      },
      {
        id: 2,
        name: 'chat',
        title: 'nav.admin.additional.chat',
        icon: 'message',
      },
      {
        id: 3,
        name: 'profileEdit',
        title: 'nav.common.additional.profile',
        icon: 'settings',
      },
    ],
  },
  [USER_TYPE.SELLER]: {
    mainNavigation: [
      {
        id: 0,
        name: 'products',
        title: 'nav.seller.main.products',
        haveMessages: false,
      },
      {
        id: 1,
        name: 'demands',
        title: 'nav.seller.main.demands',
        haveMessages: false,
      },
      {
        id: 2,
        name: 'deliveries',
        title: 'nav.seller.main.deliveries',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 0,
        name: 'clients',
        title: 'nav.seller.additional.clients',
        icon: 'team',
      },
      {
        id: 1,
        name: 'finance',
        title: 'nav.seller.additional.finance',
        icon: 'purse',
      },
      {
        id: 2,
        name: 'chat',
        title: 'nav.seller.additional.chat',
        icon: 'message',
      },
      {
        id: 3,
        name: 'profileEdit',
        title: 'nav.common.additional.profile',
        icon: 'settings',
      },
    ],
  },
  [USER_TYPE.BUYER]: {
    mainNavigation: [
      {
        id: 0,
        name: 'market',
        title: 'nav.buyer.main.market',
        haveMessages: false,
      },
      {
        id: 1,
        name: 'applications',
        title: 'nav.buyer.main.applications',
        haveMessages: false,
      },
      {
        id: 2,
        name: 'deliveries',
        title: 'nav.buyer.main.deliveries',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 0,
        name: 'providers',
        title: 'nav.buyer.additional.providers',
        icon: 'team',
      },
      {
        id: 1,
        name: 'finance',
        title: 'nav.buyer.additional.finance',
        icon: 'purse',
      },
      {
        id: 2,
        name: 'chat',
        title: 'nav.buyer.additional.chat',
        icon: 'message',
      },
      {
        id: 3,
        name: 'profileEdit',
        title: 'nav.common.additional.profile',
        icon: 'settings',
      },
    ],
  },
  [USER_TYPE.EXPORTER]: {
    mainNavigation: [
      {
        id: 0,
        name: 'products',
        title: 'nav.seller.main.products',
        haveMessages: false,
      },
      {
        id: 1,
        name: 'demands',
        title: 'nav.seller.main.demands',
        haveMessages: false,
      },
      {
        id: 2,
        name: 'deliveries',
        title: 'nav.seller.main.deliveries',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 3,
        name: 'profileEdit',
        title: 'nav.common.additional.profile',
        icon: 'settings',
      },
      {
        id: 0,
        name: 'clients',
        title: 'nav.seller.additional.clients',
        icon: 'team',
      },
      {
        id: 1,
        name: 'finance',
        title: 'nav.seller.additional.finance',
        icon: 'purse',
      },
      {
        id: 2,
        name: 'chat',
        title: 'nav.support.additional.chat',
        icon: 'message',
      },
    ],
  },
  [USER_TYPE.SUPPORT_AGENT]: {
    mainNavigation: [
      {
        id: 0,
        name: 'market',
        title: 'nav.support.main.market',
        haveMessages: false,
      },
      {
        id: 1,
        name: 'demands',
        title: 'nav.support.main.demands',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 1,
        name: 'clients',
        title: 'nav.seller.additional.clients',
        icon: 'team',
        dropdownLinks: [
          {
            name: 'clients',
            title: 'nav.seller.additional.clients',
          },
          {
            name: 'providers',
            title: 'nav.buyer.additional.providers',
          },
        ],
      },
      {
        id: 2,
        name: 'chat',
        title: 'nav.seller.additional.chat',
        icon: 'message',
      },
    ],
  },
  systemSeller: {
    mainNavigation: [
      {
        id: 0,
        name: 'products',
        title: 'nav.seller.main.products',
        haveMessages: false,
      },
      {
        id: 1,
        name: 'demands',
        title: 'nav.seller.main.demands',
        haveMessages: false,
      },
      {
        id: 2,
        name: 'deliveries',
        title: 'nav.seller.main.deliveries',
        haveMessages: false,
      },
    ],
    additionalNavigation: [
      {
        id: 0,
        name: 'clients',
        title: 'nav.seller.additional.clients',
        icon: 'team',
        label: 'nav.seller.additional.clients',
        dropdownLinks: [
          {
            name: 'clients',
            title: 'nav.seller.additional.clients',
          },
          {
            name: 'providers',
            title: 'nav.buyer.additional.providers',
          },
        ],
      },
      {
        id: 2,
        name: 'finance',
        title: 'nav.seller.additional.finance',
        icon: 'purse',
      },
      {
        id: 3,
        name: 'chat',
        title: 'nav.seller.additional.chat',
        icon: 'message',
      },
      {
        id: 4,
        name: 'profileEdit',
        title: 'nav.common.additional.profile',
        icon: 'settings',
      },
    ],
  },
  noAuth: {
    mainNavigation: [
      {
        id: 0,
        link: `${LANDING_PAGE_URL}/pro-bazu/`,
        title: 'nav.guest.main.about',
        haveMessages: false,
      },
      {
        id: 1,
        link: `${LANDING_PAGE_URL}/pokupatelyam/`,
        title: 'nav.guest.main.forBuyers',
        haveMessages: false,
      },
      {
        id: 2,
        link: 'https://seller.baza.store/',
        title: 'nav.guest.main.forSuppliers',
        haveMessages: false,
      },
      {
        id: 3,
        link: 'https://delivery.baza.store/',
        title: 'nav.guest.main.forDistributors',
        haveMessages: false,
      },
      {
        id: 4,
        modal: 'app-links-modal',
        title: 'nav.guest.main.app',
        haveMessages: false,
      },
      {
        id: 5,
        link: `${LANDING_PAGE_URL}/voprosy-i-otvety/`,
        title: 'nav.guest.main.faq',
        haveMessages: false,
      },
    ],
    additionalNavigation: [],
  },
}

export const APPLICATION_TYPE = {
  AUCTION: 'auction',
  SUGGESTION: 'suggestion',
  SUPPLY_REQUEST: 'supply-request',
}
export const DELIVERY_TYPE = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
}
export const TERM_TYPE = {
  DIRECT: 'direct',
  TRANSIT: 'transit',
  PREORDER: 'preorder',
  INSTOCK: 'instock',
}
export const OBTAIN_TYPE = {
  OWN: 'in_own_warehouse',
  SELLER: 'in_seller_warehouse',
}
export const PAYMENT_TYPE = {
  CASH: 'cash',
  CASHLESS: 'cashless',
}
export const ORDER_TYPE = {
  CART: 'CartItem',
  SUGGESTION: 'suggestion',
  AUCTION: 'auction',
}

export const INCOTERMS = [
  {
    id: 'EXW',
    name: 'EXW',
  },
  {
    id: 'FCA',
    name: 'FCA',
  },
  {
    id: 'FAS',
    name: 'FAS',
  },
  {
    id: 'FOB',
    name: 'FOB',
  },
  {
    id: 'CFR',
    name: 'CFR',
  },
  {
    id: 'CIF',
    name: 'CIF',
  },
  {
    id: 'CIP',
    name: 'CIP',
  },
  {
    id: 'CPT',
    name: 'CPT',
  },
  {
    id: 'DAT',
    name: 'DAT',
  },
  {
    id: 'DAP',
    name: 'DAP',
  },
  {
    id: 'DDP',
    name: 'DDP',
  },
  {
    id: 'DPU',
    name: 'DPU',
  },
]

// Если есть в базе соответствущая таблица, удалить константу
export const UNIT_TYPES = [
  {
    id: 'kg',
    name: 'kg',
  },
  {
    id: 'pcs',
    name: 'pcs',
  },
]

// Query params
export const MAX_PRODUCTS_SHOW = 2

export const RECAPTCHA_SITE_KEY = '6LcYqs4aAAAAAD1Qtx_JJ9ylOI9SOVPvu1eL-5To'

export const BAZA_INN = '7801693173'

export const FACE_TYPES = {
  company: 'company',
  individual: 'individual',
}

// Санкт-Петербург ул Софийская 60 ....
export const DEFAULT_PICKUP_WAREHOUSE_ID = process.env.NODE_ENV === 'production' ? 15 : 78

export const GOOGLE_ANALYTICS_KEY =
  location.hostname !== 'localhost' && !location.hostname.startsWith('dev.') ? 'G-S7SR01LD3K' : ''

// Этого не должно быть
export default {
  USER_TYPE,
  USER_NAVIGATION,
}

export const fallbackKindsOfActivity = [
  {
    id: 1,
    name: 'Manufacturer',
    name_ru: 'Производитель',
    name_en: 'Manufacturer',
  },
  {
    id: 2,
    name: 'Distributor',
    name_ru: 'Дистрибьютор',
    name_en: 'Distributor',
  },
  {
    id: 3,
    name: 'Importer',
    name_ru: 'Импортёр',
    name_en: 'Importer',
  },
  {
    id: 4,
    name: 'Reseller',
    name_ru: 'Торговый посредник',
    name_en: 'Reseller',
  },
  {
    id: 5,
    name: 'Federal Supplier',
    name_ru: 'Федеральный поставщик',
    name_en: 'Federal Supplier',
  },
  {
    id: 6,
    name: 'Farming',
    name_ru: 'Фермерское хозяйство',
    name_en: 'Farming',
  },
  {
    id: 7,
    name: 'Exporter',
    name_ru: 'Экспортёр',
    name_en: 'Exporter',
  },
]

export const initialDeliveryInfo = {
  days: null,
  min_weight_kg: null,
  price_kg: null,
}

export const RUSSIA_COUNTRY_ID = 39

export const SELLER_PRODUCT_STATUS = {
  moderation: 'moderation',
  reject: 'reject',
  publish: 'publish',
  not_publish: 'not_publish',
}

export const WEIGHT_UNIT_NAMES = {
  kg: 'kg',
  l: 'l',
  pcs: 'pcs',
  pkg: 'pkg',
}

export const PAYMENT_VARIANTS = {
  byCard: 'by-card',
  byInvoice: 'by-invoice',
  byCash: 'by-cash',
}

export const PRODUCT_VAT_OPTIONS = [0, 10, 20]

export const PHONE_ALREADY_VERIFIED_ERRORS = [
  'Phone number is already verified.',
  'Телефонный номер уже верифицирован.',
]

export const YANDEX_METRICA_ID = 99669223

export const ORDER_DELIVERY_HOURS_RANGE = 4

export const MARKET_RELATED_ROUTE_NAMES = [
  'market',
  'marketSearch',
  'productsByCategory',
  'productsByProduct',
  'productDetail',
]
