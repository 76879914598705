<template>
  <footer ref="appFooter" class="footer">
    <div class="footer__inner">
      <div v-for="item in footerItems" :key="item.id" :class="['footer__item', `footer__item--${item.id}`]">
        <p class="footer__item-lead">{{ item.title }}</p>

        <ul
          class="footer-list footer__list"
          :class="{
            'footer__list--app': item.id === 'app',
          }"
        >
          <li v-for="link in item.links" :key="link.title" class="footer-list__item">
            <a :href="link.href" target="_blank" class="footer-list__link">
              <span v-if="link.title">{{ link.title }}</span>
              <img v-if="link.image" :src="link.image.src" :alt="link.image.alt" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { LANDING_PAGE_URL } from '@/constants'
import { debounce } from 'lodash'

export default {
  name: 'AppFooter',
  computed: {
    footerItems() {
      return [
        {
          id: 'buyers',
          title: this.$t('footer.forBuyers'),
          links: [
            {
              title: this.$t('footer.howToMakeOrder'),
              href: `${LANDING_PAGE_URL}/faq/zakazy-i-postavki/`,
            },
            {
              title: this.$t('footer.paymentMethod'),
              href: `${LANDING_PAGE_URL}/faq/oplata/`,
            },
            {
              title: this.$t('footer.delivery'),
              href: `${LANDING_PAGE_URL}/faq/dostavka/`,
            },
            {
              title: this.$t('footer.returnPolicy'),
              href: `${LANDING_PAGE_URL}/faq/vozvraty-i-otmeny/`,
            },
            {
              title: this.$t('footer.refund'),
              href: `${LANDING_PAGE_URL}/faq/oplata/`,
            },
            {
              title: this.$t('footer.rulesOfTrading'),
              href: `${LANDING_PAGE_URL}/faq/documenty/`,
            },
            {
              title: this.$t('footer.docs'),
              href: `${LANDING_PAGE_URL}/faq/documenty/`,
            },
            {
              title: this.$t('footer.faq'),
              href: `${LANDING_PAGE_URL}/faq/glavnaya/`,
            },
          ],
        },
        {
          id: 'partners',
          title: this.$t('footer.forPartners'),
          links: [
            {
              title: this.$t('footer.forSuppliers'),
              href: 'https://seller.baza.store/',
            },
            {
              title: this.$t('footer.forExporters'),
              href: 'https://seller.baza.store/',
            },
          ],
        },
        {
          id: 'about',
          title: this.$t('footer.aboutBaza'),
          links: [
            {
              title: this.$t('footer.aboutCompany'),
              href: `${LANDING_PAGE_URL}/pro-bazu/`,
            },
            {
              title: this.$t('footer.jobs'),
              href: `${LANDING_PAGE_URL}/vakansii/`,
            },
            {
              title: this.$t('footer.privacyPolicy'),
              href: `${LANDING_PAGE_URL}/privacy-policy`,
            },
            {
              title: this.$t('footer.userAgreement'),
              href: `${LANDING_PAGE_URL}/agreement`,
            },
          ],
        },
        {
          id: 'app',
          title: this.$t('footer.mobileApp'),
          links: [
            {
              image: {
                src: require('@/assets/images/common/appstore-ru.svg'),
                alt: this.$t('footer.downloadInAppStore'),
              },
              href: 'https://apps.apple.com/ru/app/baza-store/id1605583568',
            },
            {
              image: {
                src: require('@/assets/images/common/google-play-ru.svg'),
                alt: this.$t('footer.downloadInGooglePlay'),
              },
              href: 'https://play.google.com/store/apps/details?id=com.bazastore',
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.updateFooterHeightInState()
    window.addEventListener('resize', this.updateFooterHeightInState)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateFooterHeightInState)
  },
  methods: {
    updateFooterHeightInState: debounce(function () {
      const height = this.$refs.appFooter.getBoundingClientRect().height
      this.$store.dispatch('setFooterHeight', Math.round(height || 0))
    }, 200),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.footer {
  padding: 20px;
  position: relative;
  z-index: 1001;
  color: $White;

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'app' 'buyers' 'partners' 'about';
    gap: 32px;
    max-width: 900px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, auto);
      grid-template-areas: 'buyers partners' 'buyers app' 'about .' 'about .';
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);

      grid-template-areas:
        'buyers partners app'
        'buyers about app';
    }

    @include media-breakpoint-up(lg) {
      row-gap: 40px;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 24px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  &__list.footer__list--app {
    grid-template-columns: repeat(2, max-content);
    gap: 12px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    align-items: flex-start;
    align-content: flex-start;

    &--buyers {
      grid-area: buyers;
    }

    &--partners {
      grid-area: partners;
    }

    &--about {
      grid-area: about;
    }

    &--app {
      grid-area: app;
    }
  }

  &__item-lead {
    font-weight: 500;
    font-size: 20px;
  }
}

.footer-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  &__link {
    color: inherit;
    text-decoration: none;
    display: block;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }

    > img {
      max-width: 130px;
    }
  }
}
</style>
